import { Component, Vue, Prop } from 'vue-property-decorator';
import imFetch from '@/api/imFetch';

@Component
export default class Export extends Vue {
    @Prop()
    project: any;

    loading = false;

    versie = '14.8.0';
    sender = false;
    dossier = false;
    multisurface = true;
    refresh = false;
    versionCodes = false;

    versies = ['12.1.0', '13.3.0', '14.3.0', '14.7.0', '14.8.0'];

    async exportProject() {
        try {
            this.loading = true;

            const { id } = await imFetch(
                `/uitwisseling/export?project=${this.project.id}&version=${this.versie}&sender=${this.sender}&dossier=${this.dossier}&multisurface=${this.multisurface}&version_codes=${this.versionCodes}&refresh=${this.refresh}`
            );

            this.$router.push({
                name: 'taak',
                params: {
                    id,
                },
            });
        } catch (e) {
            throw new Error(String(e));
        } finally {
            this.loading = false;
        }
    }
}
